
@media only screen and (max-width: 769px) {
  
    .header-top {
      font-size: 0.7rem;
    }

    h1,h2,h3,h4 {font-size: 1.2rem}

  .slider .articles_bxslider .bx-wrapper .article_sans_image {bottom: 32% !important}
  .slider .overlay .module_links_content .cta div.link {font-size: 1rem !important;padding: 8px !important;}
  .slider .overlay .module_links_content {margin: 0% 25% !important;}
  .product_detail a.btn {display: none !important;}
  .dropdown-menu.show {left: -152px !important;}
  .button2 {width:40% !important;margin-left: 5% !important;}
  .button1 {width:40% !important;margin-right: 5% !important;}
  .shoppingcart_extended_items .sc_ext_name {width:72% !important}
  .shoppingcart_item_list table tr.sc_ext_header {display:none;}
  .shoppingcart_extended_items .sc_ext_total_incl {width:170% !important;}
  .chkwin-bringtofront .chkwin-skin .chkwin-inner .checkout_container ol li {width:100% !important;}
  .checkout_login_wrapper {padding:0px !important;}
}
