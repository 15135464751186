/* temp */

.header-menu, .breadcrumb {
  display:none !important;
}

body {
    background-color: $white;
    color: $black;
    font-family: $font-family-base;
}

h1, h2, h3, h4, h5, h6 {}

#ajax-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        width: 50px;
        height: 50px;

        svg {
            font-size: 50px;
        }
    }
}


@import "header";
@import "product_list";
@import "footer";


.slider {
  
  position: relative;
  
  .overlay {
    position: absolute;
    z-index:100;
    width:100%;
    height:100%;
    bottom: 0%;
    margin: 0% auto;
    text-align:center;
    background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.6));
    
      .module_links_content {
        position: absolute;
        width:100%;text-align:center;
        margin: 0% 40%;
        bottom: 15%;
          .cta {
            div.link {
              position: relative;float:left;
              background-color:$primary;
              color:$white;
              padding: 10px;
              border-radius: 5px;
              text-align: center;
              margin: 0px 20px;
              font-weight:bold;
              font-size: 1.2rem;
              
                a {color:$white;text-decoration:none;}
            }  
            div.linkdescr {display:none;}
          }
      }
  }
  
 .articles_bxslider {
    position: relative;
    
    .bx-wrapper {
      
      box-shadow:0px !important;
      border: 0px !important;
      background: transparent !important;
 
      .article_sans_image {
        position:absolute;
        width:100%;
        text-align:center;
        bottom: 50%;
        z-index:9998;
        
          h2.article_title {
            color:$white;
            -webkit-text-stroke: 1px $primary;
             text-align:center;
             font-weight:bold;
          }
         .article_introtext {
           font-weight:bold;
           font-size: 1.5rem;
           color: $white;
           text-align:center;
         }
        }
        
        .bx-pager {bottom: 20px !important;} 
      }
    }
}


.row1 {
      h2 {text-align:center;}
      .article_spacer {width:10%;background-color:$primary;height:4px;margin: 2% 45%;}
      .article_introtext {text-align:center;}
}

.row2 {
 
  .article {
      display:flex;
      flex-direction: row;
      flex-wrap: no-wrap;
        
         .text-simpleimage { flex-grow: 1;
                .overlay {position: relative;background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.8));width:100%;height:35%;bottom:35%;}
         
                .firstrow {
                  img {width:100%;}
                }
                .titlerow, .secondrow {position: absolute;text-align:center;color:$white;}
                .titlerow {position:relative;bottom: 0%;text-align:center;}
                .secondrow {position:relative;bottom: 0%;font-size:1.5rem;}
          }
         .text-simpleimage:nth-child(1) {margin-right:40px;}
      
  }
}


.row3 {
    margin-bottom: 40px;
    h2 {text-align:center;}
    .article_spacer {width:10%;background-color:$primary;height:4px;margin: 2% 45%;}
    .article_introtext {text-align:center;}
}

.product_detail a.btn {
    float: right;
    margin-top: -60px;
    margin-right: 100px;
}


.footer {
  h1, h2, h3 {text-transform: uppercase;font-size: 1.2rem;font-weight:bold;}
  a {color:$white;text-decoration:none;}
  
  .form_input_text {
    position: relative;float:left;
    width:60%;
    
    label.form_label {display:none;}
    input[type=text] {padding: 5px 10px;border-radius:4px 0px 0px 4px;border:0px;width:100%;}
    
  }
  
  .form_buttons {
     position: relative;float:left;
    width:40%;
    input[type="submit"] {padding: 5px 10px;border-radius:0px 4px 4px 0px;border:0px;background-color:$quartiary;color:$white;font-weight:bold;}
    input[type="submit"]:hover {background-color:$black}
  }
  
  .disclaimblock {
    width:100%;margin-top: 20px;position: relative;float:left;
  }
  
  
}

.underfooter {
  background-color:$secondary;
  text-align: center;
  color:$white;
  a {color:$white;}
}

.hfunctions_content {
  padding: 35px;
  width: 350px;
  z-index: 99999;
  
        .hfunctions_products_item {
          position: relative;
          float:left;
          width:100%;
          border-bottom: 1px solid $tertiary;
          padding: 10px 0px;
          
          .quantity {width:10%;float:left;padding-top: 10px;
              .iconify {
                width:20px;height:20px;color:$primary;
              }
          }
          .name {width:70%;float:left;
            span {font-size: 1.2rem;}
            .price {color:$secondary;}
          }
        }
        .hfunctions_products_item.hfunctions_border {border:0px;}
        .product_image_slide {
          float:left;
          width:20%;
          img {width:50px;}
        }
        .hfunctions_total {
            font-size: 1.2rem;
            .price {
              float:right;
              padding: 0px 4px;
            }
          }
        .hfunctions_goto_cart {
            position: relative;
            float:left;
            margin:10px 0px;
            background-color:$primary;
            color:$white;
            font-weight:bold;
            border-radius: 4px;
            padding: 10px;
            cursor:pointer;
        }
        .hfunctions_goto_cart:hover {
          background-color:$yellow;
          color:$secondary;
        }
}


/* CHECKOUT */



.chkwin-bringtofront {
  
  .chkwin-skin {
    background-color:$tertiary;color:$white;
    
    .chkwin-inner {
      
        .checkout_container {
          position: relative;
          float: left;
          width: 100%;
          padding: 20px;
          margin-top: 50px;
          background: $tertiary;
          color:$secondary;
          
          ol {
            list-style: none;
            font-size: 1.2rem;
            padding-left: 0;
            margin-top: 0;
            
            li {
               padding: 10px;
                float: left;
                width: 25%;
            }
            li.active {
              background-color: $secondary;
              color:$white;
              :hover {}
            }
            li.clickable {color:$primary;
              &:hover {color: $secondary;cursor:pointer;}
            }
            li.step_0 {
              border: none;
            }
          } 
          
          .checkout_wrapper
          {
            margin-top: 35px;
            position: relative;
            float: left;
            width: 100%;
            
          .checkout_title {
            display:none;
            }
          }
          
         
          
          .shoppingcart_item_list {
            color: $secondary;
            position: relative;float:left;
            margin-top: 30px;
            margin-bottom: 30px;
            width: 100%;
            
            a {color:$secondary;font-weight:bold;}
            
            table {
                width: 100%;

                tr.shoppingcart_content { 
                  border-bottom: 1px solid $secondary;
                
                td {
                padding: 2px 4px;
                text-align: center;
                }
              }
            }
          }
          
          .shoppingcart_voucher {
              display:none;
              position: relative;
              float:left;
              margin-top: 20px;
              width:100%;
              margin-bottom: 30px;
              
             .sc_widget_header {
               font-size: 1.4rem;
               color: $primary;
              
             }
             input {
                padding: 4px;
                border: 0px;
             &.voucher_code {
               background-color:$tertiary;
             }
             &.voucher_redeem {
               background-color:$primary;
               color:$white;
               font-weight:bold;
             }
          }
          
        }
        
        .checkout_payment_wrapper {
          float:left;
          margin: 20px 0px;
          
            .payment_method_description_text
            {
              float: left;
              font-size: 0.8rem;
            }
          
        }
        
        
        .checkout_tooltip
        {
          background-color: transparent;
          position: relative;
          float: left;
          width: 100%;
          color: $secondary;
          font-size: 0.9rem;
          
          .title {
            
               font-size: 1.4rem;
               color: $primary;
          }
          
        }
        
        .shoppingcart_extended_totals {
          float:right;
          td {padding: 2px 4px;}
        }
         
      }
    }
  }

}


        .button1{width:20%;float:left;margin-right:30%;background-color:$primary;color:$white;font-size: 1rem;border:0px;
                padding: 10px;text-align:center;
                &:hover {color:$secondary;background-color:$secondary;}
                input {border: 0px;background-color:transparent;color:$white;}
              }
        .button2{width:20%;float:right;margin-left:30%;background-color:$primary;color:$white;font-size: 1rem;border:0px;
                padding: 10px;text-align:center;
                &:hover {color:$secondary;background-color:$secondary;}
                input {border: 0px;background-color:transparent;color:$white;}
            }

ul.form-list {list-style-type:none}
ul.form-list label {margin-top: 20px;}



.subscription_button_container .button-op-rekening {
  margin-right: 10px;
}

.shoppingcart_item_list table tr.shoppingcart_content.package_header {
  background: #231f20;
}

.shoppingcart_item_list table tr.shoppingcart_content.package_header td.scc_name {
  padding: 4px 10px;
  color: #FFFFFF;
  font-size: 24px;
  border-left: 1px solid #231f20;
}

.shoppingcart_item_list table tr.shoppingcart_content.package_header td.sc_ext_delete {
  border-right: 1px solid #231f20;
}


.shoppingcart_item_list table tr.shoppingcart_content.in_package .sc_ext_img {
  border-left: 1px solid #231f20;
  padding-left: 10px;
}
.shoppingcart_item_list table tr.shoppingcart_content.in_package .sc_ext_delete {
  border-right: 1px solid #231f20;
  padding-right: 10px;
}

.shoppingcart_item_list table tr.shoppingcart_content.in_package + .shoppingcart_content td {
  border-top: 1px solid #231f20;
}
.shoppingcart_item_list table tr.shoppingcart_content.in_package + .shoppingcart_content.in_package td {
  border-top: 0;
}

.hfunctions_products_item.in_package + .hfunctions_products_item .name {
  padding-top: 10px;
}

.shoppingcart_item_list table tr.shoppingcart_content td.scc_name {text-align:left}


.shoppingcart_item_list table a {
  color: #000000;
}

.shoppingcart_extended_items .sc_ext_header .sc_ext_img,
.shoppingcart_extended_items .sc_ext_header .sc_ext_name,
.shoppingcart_extended_items .sc_ext_header .sc_ext_item_excl,
.shoppingcart_extended_items .sc_ext_header .sc_ext_action,
.shoppingcart_extended_items .sc_ext_header .sc_ext_quantity,
.shoppingcart_extended_items .sc_ext_header .sc_ext_delete {
  visibility: hidden;
}

.shoppingcart_extended_items .sc_ext_img    { min-width: 10px; }
.shoppingcart_extended_items .sc_ext_name     { min-width: 200px; }
.shoppingcart_extended_items .sc_ext_item_excl  { min-width: 40px; }
.shoppingcart_extended_items .sc_ext_item_incl  { min-width: 40px; }
.shoppingcart_extended_items .sc_ext_action   { min-width: 0px; }
.shoppingcart_extended_items .sc_ext_quantity   { width: 120px; }
.shoppingcart_extended_items .sc_ext_total_excl { min-width: 120px; }
.shoppingcart_extended_items .sc_ext_total_incl { min-width: 120px; }
.shoppingcart_extended_items .sc_ext_delete   { min-width: 10px; }

.shoppingcart_extended_items .sc_ext_quantity label {
  display: none;
}

.shoppingcart_extended_items .sc_ext_quantity input {
  width: 40px;
  background-color: $tertiary;
  border: 0px;
  text-align: center;
  padding: 4px;
  color:$secondary;
}
  
.shoppingcart_extended_items .sc_ext_quantity span {
    position: relative;
    float: left;
    font-size: 22px;
    cursor: pointer;
    margin: 5px 0 0 8px;
    color: #867f7b;
}

.shoppingcart_extended_totals {
  width: 100%;
  max-width: 300px;
}

.scc_delete {
  cursor: pointer;
  color: $primary;
  font-size: 16px;
}

.scc_delete:hover i {
  font-weight: bold;
}

.sc_widget.shoppingcart_cadeau {
  display:none;
    position: relative;
    float: left;
    padding: 14px 30px;
  background: #f5f4f4;
  border: 1px solid #e7e7e7;
    font-size: 16px;
    line-height: 26px;
    margin-top: 20px;
    width: 100%;
}

.togglecadeau {
    position: relative;
    float: left;
  background: $secondary;
  border: 1px solid #e7e7e7;
  padding: 10px;
}

.togglecadeau .sc_widget_field {
    position: relative;
    float: left;
    clear: both;
    width: 400px;
}

.togglecadeau .sc_widget_field label {
    position: relative;
    float: left;
    width: 100%;
}

.togglecadeau .sc_widget_input {
    position: relative;
    float: left;
    margin-bottom: 10px;
    width: 100%;
}


@media screen and (max-width: 769px) {
  
  .shoppingcart_extended_items tr.sc_ext_header .sc_ext_quantity { display: none; }
  
  .shoppingcart_extended_items .sc_ext_item_excl  { display: none; }
  .shoppingcart_extended_items .sc_ext_item_incl  { display: none; }
  .shoppingcart_extended_items .sc_ext_total_excl { display: none; }
  
  .shoppingcart_extended_items .sc_ext_img    { width: 15%; min-width: auto; }
  .shoppingcart_extended_items .sc_ext_name     { width: 42%; min-width: auto; }
  .shoppingcart_extended_items .sc_ext_action   { width: 13%; min-width: auto; }
  .shoppingcart_extended_items .sc_ext_total_incl { width: 20%; min-width: auto; }
  .shoppingcart_extended_items .sc_ext_delete   { width: 10%; min-width: auto; }
  
  .shoppingcart_item_list table tr.sc_ext_header,
  .shoppingcart_item_list table tr.shoppingcart_content {
    position: relative;
    float: left;
    width: 100%;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content {
    margin-bottom: 20px;
  }
  
  .shoppingcart_item_list table tr.sc_ext_header th {
    position: relative;
    float: left;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content td {
    position: relative;
    float: left;
    padding-bottom: 40px;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content td.sc_ext_img {
    padding-left: 0;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content.package_header td {
    padding-bottom: 0 !important;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content.in_package td.scc_name {
    padding-left: 10px;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content.package_header td.scc_name {
    width: 100% !important;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content.package_header td .scc_no_delete {
    display: none;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content.in_package + .shoppingcart_content td,
  .shoppingcart_item_list table tr.shoppingcart_content.in_package .sc_ext_img {
    border: 0;
  }
  .shoppingcart_item_list table tr.shoppingcart_content.in_package .sc_ext_delete {
    border: 0;
    padding-right: 0;
  }
  .shoppingcart_item_list table tr.shoppingcart_content.in_package + .shoppingcart_content {
      border-top: 1px solid #231f20;
  }
  .shoppingcart_item_list table tr.shoppingcart_content.in_package + .shoppingcart_content.in_package {
      border-top: 0;
  }
  
  .shoppingcart_item_list table tr.shoppingcart_content.in_package {
      border-left: 1px solid $secondary;
      border-right: 1px solid $secondary;
      padding: 10px 0 10px 10px;
  }
  
  
  .shoppingcart_item_list table tr.shoppingcart_content .sc_ext_quantity {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
  }
  
  .shoppingcart_extended_items .sc_ext_quantity label {
    display: block;
    position: relative;
    float: left;
    font-size: 16px;
    margin-right: 10px;
  }

  .shoppingcart_extended_items .sc_ext_quantity input {
    width: 60px;
    float: left;
  }
  
  .shoppingcart_extended_items .sc_ext_quantity span {
    margin-top: -1px;
  }
  
  .shoppingcart_extended_items .sc_ext_img img {
    /* width: 32px; */
    width: 100%;
    height: auto;
  }
  
 
  
  .checkout_wrapper {
    padding: 0px;
  }
  
  .page_content .checkout_wrapper {
    padding: 20px 0;
  }
  
  .checkout_tabs_container {
    height: auto;
  }
  
  .checkout_tabs_container ol li {
    width: 100%;
    padding: 10px 20px;
    border-top: 2px solid #e7e7e7;
    border-left: none;
    
    display: none;
  }
  
  .checkout_tabs_container ol li.active {
    display: block;
  }
  
  .shoppingcart_button_container input,
  .subscription_button_container input,
  .payment_button_container input {
    padding: 0 2px;
  }
  
  .sc_widget.shoppingcart_cadeau {
    width: 100%;
    padding: 14px;
  }
  
  .togglecadeau .sc_widget_field {
    width: 100%;
  }
}

.checkout_subscription_container {
  
  .field_object {
      
      input, select, textarea {width: 100%;padding: 4px;background-color: #f3c384;border: 0px;}
      input[type="radio"], input[type="checkbox"] {width: 40px; }
   }
  
}

.checkout_login_wrapper {
    position: relative;
    float: left;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
}

.checkout_login_wrapper h4 {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
}

.checkout_login_wrapper .passwordforgotten,
.checkout_login_wrapper .password_remember {
    position: relative;
    float: left;
    width: 100%;
}

.address-order {
    margin-bottom: 20px;
}

.address-order h3,
.address-order .order-information .reservation_block.reservation_comments label {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
}

.address-order .address-information {
    position: relative;
    float: left;
    width: 100%;
    padding: 20px;
    background-color:$tertiary;
    
    label {margin-top: 10px;}
}

.checkout_subscription_container .field_wrap {
  position: relative;
  float: left;
  width: 100%;
}

.address-order .order-information .reservation_block {
  position: relative;
    float: left;
    width: 100%;
    padding: 20px;
}

.address-order .order-information .reservation_block.reservation_comments {
  margin-top: 20px;
}


.checkout_payment_left,
.payment_methods_ct {
  position: relative;
  float: left;
  width: 100%;
  background-color:$black;
  color:$white;
   a {color:$white !important;}
}

.payment_method_item {
  position: relative;
  float: left;
}

#payment_method_description {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 20px;
}

.payment_form_agree {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 20px;
}

.payment_method_item {
  position: relative;
  float: left;
  width: 50%;
  padding: 4px 10px 4px 0;
}

.payment_method_choice {
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 2;
}

.payment_method_item {
  > input {
    display:none;
    
    &:checked + label {
      background: $primary;
    }
  }
  
}

.payment_method_label {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding-left: 20px;
}

.payment_method_image {
  position: relative;
  float: left;
  width: 40px;
  margin-right: 10px;
  height: 40px;
}

.payment_method_description {
  position: relative;
  float: left;
}

.payment_method_total {
  position: relative;
  float: left;
  width: 100%;
}

.payment_method_details {
  position: relative;
  float: left;
  width: 100%;
}

.payment_method_description a {
  float: left;
  color: #000000;
  text-decoration: none;
  font-weight: normal;
  line-height: 40px;
}

.checkout_payment_right {
  position: relative;
  float: left;
  width: 100%;
  max-width: 400px;
  background-color:$tertiary;
}

.checkout_payment_right .payment_widget {
  padding: 20px;
  margin-bottom: 20px;
}

.checkout_payment_left .payment_form_voucher {display:none;}


#payment_cadeau {display:none}

.checkout_payment_right .payment_widget_header {
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 8px;
}

.checkout_payment_right .payment_widget_fsheader {
    font-size: 16px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 0;
    margin-top: 10px;
}

.checkout_payment_right .payment_contactdelivery label, .pwf_billing_user_customer_id {
  display:none;
    width: 50%;
    padding-left: 20px;
}

.product_price_small {display:none;}

@media screen and (max-width: 769px) {
  .payment_method_item {
    width: 100%;
    padding: 4px 0;
  }
}

 .shop_unsubscribe_mailinglist {
    input[type="text"] {background-color:$black;color:$white;border: 0px;padding:4px;}
    input[type="submit"] {background-color:$primary;color:$white;border:0px;padding:4px;}
  }

.dit-page-shop-auth {
    .module-resultaat.wrapper {
        margin-top: 50px;

        .leftcol,
        .rightcol {
            background-color: $tertiary;
            padding: 40px;

            h2 {
                color: $secondary;
                margin-bottom: 20px;
                font-size: 1.4rem;
                text-align:center;
            }

            .new_formButton {
                    margin-top: 40px;
                    position: relative;
                    padding: 4px 10px;
                    float: left;
                    background-color: $primary !important;
                    border:0px;
                    border-radius:4px;
                    color: $white;
                }
            .new_formButton:hover {background-color:$yellow;color:$secondary;}
            
            .form_ct {
                margin-top: 0px;

                .radio_wrapper {
                    position: relative;
                    float: left;
                    width: 40%;
                }

                .formEntry {
                    margin-bottom: 4px;
                }

                label.form_label {
                    width: 38%;
                }

                input[type="text"],
                input[type="password"],
                textarea,
                select {
                    width: 60%;
                    background-color: $white;
                    padding: 4px !important;
                    border-radius:4px;
                    border: 0px;
                    color: $secondary;

                }
                

                .new_formButton {
                    left: 0px;
                    float: left;
                    margin: 20px 0px;
                }

                .validation-advice {
                    padding-left: 0px;
                    color: $primary;
                }

                .register {

                    .form_radios {
                        .radio_wrapper {
                            input {
                                display: none;
                            }
                        }

                        label {
                            color: $primary;
                            padding: 40px;
                            font-size: 1.4rem;
                            text-align: center;
                            margin-right: 10px;
                            cursor: pointer
                        }

                        input:checked+label {
                            background-color: $primary;
                            color: $black;
                            font-weight: bold;
                        }
                    }

                }

                .form_input_radio.gender {
                    float: left;
                    width: 100%;
                    margin-top: 10px;

                    label {
                        float: left;
                    }

                    .form_radios {
                        position: relative;
                        float: left;
                        width: 50%;

                        .radio_wrapper {
                            width: 50%;

                            input {
                                float: left;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }        
          h1.title_header {font-size:2rem;}
          .ppage-order-list {
             table {width:100%;
              th {border-bottom: 1px solid $white;}
             }
          }
          .ppage-order-detail {
              a {
                background-color:$primary;
                padding: 10px;
                margin: 10px 0px;
                color:$white;
                font-weight:bold;
                &:hover {color:$black;text-decoration:none;}
              }
              .data {margin-top: 40px;}
              .order-detail {
                table.order-items {
                  tr {padding: 4px 0px;
                    td:nth-child(1), td:nth-child(4) {text-align:center;}
                    }
                  tr:nth-child(2),tr:nth-child(4) {background-color:$black}
                  th {padding:4px;border-bottom: 1px solid $white;}
                }
              }
          }
        }

        .rightcol {
            .form_ct {
                background: $secondary;
                padding: 20px;
                border-radius:4px;

                input {
                    background-color: $white !important;
                }
                label {color:$white !important;}
                .new_formButton {
                    margin-top: 40px;
                    position: relative;
                    padding: 4px 10px;
                    float: left;
                    background-color: $primary !important;
                    border:0px;
                    border-radius:4px;
                    color: $white;
                }
              .new_formButton:hover {background-color:$yellow;color:$secondary;}
            }
            
            .ppage-data, .ppage-password {
           
                fieldset {
                  input[type="text"], select, input[type="password"] {width:90%;background-color:#f3c384;border:0px;}
                  label {margin-top: 10px;}
                }
              }
            a#reset_password_link {
              position: relative;float:left;width:100%;
            }           
        }
    }
}
