//
// Animate.css
// --------------------------------------------------

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    }
  }
  
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    opacity: 1;
  }
  
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  .animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  html:not(.lt-ie10) .not-animated{
    opacity: 0;
  }
  
  html[data-x-mode]:not(.lt-ie10) .not-animated {
    opacity: 1;
  }
  
  // Bounce Keyframes Animation
  // --------------------------------------------------
  
  @-webkit-keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }
  
    40%, 43% {
      -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }
  
    70% {
      -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }
  
    90% {
      -webkit-transform: translate3d(0,-4px,0);
      transform: translate3d(0,-4px,0);
    }
  }
  
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  
  // Flas Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(flash){
    0%, 50%, 100% {
      opacity: 1;
    }
  
    25%, 75% {
      opacity: 0;
    }
  }
  
  .flash {
    -webkit-animation-name: flash;
    animation-name: flash;
  }
  
  // Pulse Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(pulse) {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
  
  // RubberBand Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rubberBand){
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      -webkit-transform: scale3d(.95, 1.05, 1);
      transform: scale3d(.95, 1.05, 1);
    }
  
    75% {
      -webkit-transform: scale3d(1.05, .95, 1);
      transform: scale3d(1.05, .95, 1);
    }
  
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  .rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
  }
  
  // Shake Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(shake){
    0%, 100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    10%, 30%, 50%, 70%, 90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }
  
    20%, 40%, 60%, 80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }
  
  .shake {
    -webkit-animation-name: shake;
    animation-name: shake;
  }
  
  // Swing Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(swing){
    20% {
      -webkit-transform: rotate3d(0, 0, 1, 15deg);
      transform: rotate3d(0, 0, 1, 15deg);
    }
  
    40% {
      -webkit-transform: rotate3d(0, 0, 1, -10deg);
      transform: rotate3d(0, 0, 1, -10deg);
    }
  
    60% {
      -webkit-transform: rotate3d(0, 0, 1, 5deg);
      transform: rotate3d(0, 0, 1, 5deg);
    }
  
    80% {
      -webkit-transform: rotate3d(0, 0, 1, -5deg);
      transform: rotate3d(0, 0, 1, -5deg);
    }
  
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }
  
  .swing {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
  }
  
  // Tada Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(tada){
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    10%, 20% {
      -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
  
    30%, 50%, 70%, 90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
  
    40%, 60%, 80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
  
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  .tada {
    -webkit-animation-name: tada;
    animation-name: tada;
  }
  
  // Wobble Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(wobble){
    0% {
      -webkit-transform: none;
      transform: none;
    }
  
    15% {
      -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
      transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
  
    30% {
      -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
      transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
  
    45% {
      -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
      transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
  
    60% {
      -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
      transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
  
    75% {
      -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
      transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
  
    100% {
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
  }
  
  // BounceIn Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceIn){
    0%, 20%, 40%, 60%, 80%, 100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
  
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3);
    }
  
    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
  
    40% {
      -webkit-transform: scale3d(.9, .9, .9);
      transform: scale3d(.9, .9, .9);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }
  
    80% {
      -webkit-transform: scale3d(.97, .97, .97);
      transform: scale3d(.97, .97, .97);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  .bounceIn {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
  }
  
  // BounceInDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceInDown){
    0%, 60%, 75%, 90%, 100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
  
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
      transform: translate3d(0, -3000px, 0);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
    }
  
    75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }
  
    90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }
  
    100% {
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
  }
  
  // BounceInLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceInLeft){
    0%, 60%, 75%, 90%, 100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
  
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-3000px, 0, 0);
      transform: translate3d(-3000px, 0, 0);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translate3d(25px, 0, 0);
      transform: translate3d(25px, 0, 0);
    }
  
    75% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }
  
    90% {
      -webkit-transform: translate3d(5px, 0, 0);
      transform: translate3d(5px, 0, 0);
    }
  
    100% {
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .bounceInLeft {
    -webkit-animation-name: bounceInLeft;
    animation-name: bounceInLeft;
  }
  
  // BounceInRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceInRight){
    0%, 60%, 75%, 90%, 100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
  
    0% {
      opacity: 0;
      -webkit-transform: translate3d(3000px, 0, 0);
      transform: translate3d(3000px, 0, 0);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translate3d(-25px, 0, 0);
      transform: translate3d(-25px, 0, 0);
    }
  
    75% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  
    90% {
      -webkit-transform: translate3d(-5px, 0, 0);
      transform: translate3d(-5px, 0, 0);
    }
  
    100% {
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
  }
  
  // BounceInUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceInUp){
    0%, 60%, 75%, 90%, 100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
  
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 3000px, 0);
      transform: translate3d(0, 3000px, 0);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }
  
    75% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }
  
    90% {
      -webkit-transform: translate3d(0, -5px, 0);
      transform: translate3d(0, -5px, 0);
    }
  
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .bounceInUp {
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp;
  }
  
  // BounceOut Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceOut){
    20% {
      -webkit-transform: scale3d(.9, .9, .9);
      transform: scale3d(.9, .9, .9);
    }
  
    50%, 55% {
      opacity: 1;
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3);
    }
  }
  
  .bounceOut {
    -webkit-animation-name: bounceOut;
    animation-name: bounceOut;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
  }
  
  // BounceOutDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceOutDown){
    20% {
      -webkit-transform: translate3d(0, 10px, 0);
      transform: translate3d(0, 10px, 0);
    }
  
    40%, 45% {
      opacity: 1;
      -webkit-transform: translate3d(0, -20px, 0);
      transform: translate3d(0, -20px, 0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0);
    }
  }
  
  .bounceOutDown {
    -webkit-animation-name: bounceOutDown;
    animation-name: bounceOutDown;
  }
  
  // BounceOutLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceOutLeft){
    20% {
      opacity: 1;
      -webkit-transform: translate3d(20px, 0, 0);
      transform: translate3d(20px, 0, 0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(-2000px, 0, 0);
      transform: translate3d(-2000px, 0, 0);
    }
  }
  
  .bounceOutLeft {
    -webkit-animation-name: bounceOutLeft;
    animation-name: bounceOutLeft;
  }
  
  // BounceOutRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceOutRight){
    20% {
      opacity: 1;
      -webkit-transform: translate3d(-20px, 0, 0);
      transform: translate3d(-20px, 0, 0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(2000px, 0, 0);
      transform: translate3d(2000px, 0, 0);
    }
  }
  
  .bounceOutRight {
    -webkit-animation-name: bounceOutRight;
    animation-name: bounceOutRight;
  }
  
  // BounceOutUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(bounceOutUp){
    20% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }
  
    40%, 45% {
      opacity: 1;
      -webkit-transform: translate3d(0, 20px, 0);
      transform: translate3d(0, 20px, 0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
      transform: translate3d(0, -2000px, 0);
    }
  }
  
  .bounceOutUp {
    -webkit-animation-name: bounceOutUp;
    animation-name: bounceOutUp;
  }
  
  // FadeIn Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeIn){
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  // FadeInDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInDown){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  
  // FadeInDownBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInDownBig){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
      transform: translate3d(0, -2000px, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig;
  }
  
  // FadeInLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInLeft){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }
  
  // FadeInLeftBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInLeftBig){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-2000px, 0, 0);
      transform: translate3d(-2000px, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
  }
  
  // FadeInRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInRight){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  
  // FadeInRightBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInRightBig){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(2000px, 0, 0);
      transform: translate3d(2000px, 0, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
  }
  
  // FadeInUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInUp){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
  
  // FadeInUpBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeInUpBig){
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig;
  }
  
  // FadeOut Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOut){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
  
  // FadeOutDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutDown){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  
  .fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
  }
  
  // FadeOutDownBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutDownBig){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, 2000px, 0);
      transform: translate3d(0, 2000px, 0);
    }
  }
  
  .fadeOutDownBig {
    -webkit-animation-name: fadeOutDownBig;
    animation-name: fadeOutDownBig;
  }
  
  // FadeOutLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutLeft){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
  }
  
  // FadeOutLeftBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutLeftBig){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(-2000px, 0, 0);
      transform: translate3d(-2000px, 0, 0);
    }
  }
  
  .fadeOutLeftBig {
    -webkit-animation-name: fadeOutLeftBig;
    animation-name: fadeOutLeftBig;
  }
  
  // FadeOutRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutRight){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  
  .fadeOutRight {
    -webkit-animation-name: fadeOutRight;
    animation-name: fadeOutRight;
  }
  
  // FadeOutRightBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutRightBig){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(2000px, 0, 0);
      transform: translate3d(2000px, 0, 0);
    }
  }
  
  .fadeOutRightBig {
    -webkit-animation-name: fadeOutRightBig;
    animation-name: fadeOutRightBig;
  }
  
  // FadeOutUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutUp){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  }
  
  .fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
  }
  
  // FadeOutUpBig Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(fadeOutUpBig){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
      transform: translate3d(0, -2000px, 0);
    }
  }
  
  .fadeOutUpBig {
    -webkit-animation-name: fadeOutUpBig;
    animation-name: fadeOutUpBig;
  }
  
  // Flip Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(flip){
    0% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  
    40% {
      -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  
    50% {
      -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  
    80% {
      -webkit-transform: perspective(400px) scale3d(.95, .95, .95);
      transform: perspective(400px) scale3d(.95, .95, .95);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  }
  
  .animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip;
  }
  
  // FlipInX Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(flipInX){
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      opacity: 0;
    }
  
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
    }
  
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }
  
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
  
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }
  
  .flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
  }
  
  // FlipInY Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(flipInY){
    0% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      opacity: 0;
    }
  
    40% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
      -webkit-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
    }
  
    60% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
      opacity: 1;
    }
  
    80% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }
  
  .flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
  }
  
  // FlipOutX Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(flipOutX){
    0% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  
    30% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      opacity: 0;
    }
  }
  
  .flipOutX {
    -webkit-animation-name: flipOutX;
    animation-name: flipOutX;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
  }
  
  // FlipOutY Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(flipOutY){
    0% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  
    30% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      opacity: 0;
    }
  }
  
  .flipOutY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipOutY;
    animation-name: flipOutY;
    -webkit-animation-duration: .75s;
    animation-duration: .75s;
  }
  
  // LightSpeedIn Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(lightSpeedIn){
    0% {
      -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
      transform: translate3d(100%, 0, 0) skewX(-30deg);
      opacity: 0;
    }
  
    60% {
      -webkit-transform: skewX(20deg);
      transform: skewX(20deg);
      opacity: 1;
    }
  
    80% {
      -webkit-transform: skewX(-5deg);
      transform: skewX(-5deg);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  
  .lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  
  // LightSpeedOut Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(lightSpeedOut){
    0% {
      opacity: 1;
    }
  
    100% {
      -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
      transform: translate3d(100%, 0, 0) skewX(30deg);
      opacity: 0;
    }
  }
  
  .lightSpeedOut {
    -webkit-animation-name: lightSpeedOut;
    animation-name: lightSpeedOut;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  
  // RotateIn Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateIn){
    0% {
      -webkit-transform-origin: center;
      transform-origin: center;
      -webkit-transform: rotate3d(0, 0, 1, -200deg);
      transform: rotate3d(0, 0, 1, -200deg);
      opacity: 0;
    }
  
    100% {
      -webkit-transform-origin: center;
      transform-origin: center;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  
  .rotateIn {
    -webkit-animation-name: rotateIn;
    animation-name: rotateIn;
  }
  
  // RotateInDownLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateInDownLeft){
    0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);
      opacity: 0;
    }
  
    100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  
  .rotateInDownLeft {
    -webkit-animation-name: rotateInDownLeft;
    animation-name: rotateInDownLeft;
  }
  
  // RotateInDownRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateInDownRight){
    0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg);
      opacity: 0;
    }
  
    100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  
  .rotateInDownRight {
    -webkit-animation-name: rotateInDownRight;
    animation-name: rotateInDownRight;
  }
  
  // RotateInUpLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateInUpLeft){
    0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg);
      opacity: 0;
    }
  
    100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  
  .rotateInUpLeft {
    -webkit-animation-name: rotateInUpLeft;
    animation-name: rotateInUpLeft;
  }
  
  // RotateInUpRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateInUpRight){
    0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate3d(0, 0, 1, -90deg);
      transform: rotate3d(0, 0, 1, -90deg);
      opacity: 0;
    }
  
    100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  
  .rotateInUpRight {
    -webkit-animation-name: rotateInUpRight;
    animation-name: rotateInUpRight;
  }
  
  // RotateOut Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateOut){
    0% {
      -webkit-transform-origin: center;
      transform-origin: center;
      opacity: 1;
    }
  
    100% {
      -webkit-transform-origin: center;
      transform-origin: center;
      -webkit-transform: rotate3d(0, 0, 1, 200deg);
      transform: rotate3d(0, 0, 1, 200deg);
      opacity: 0;
    }
  }
  
  .rotateOut {
    -webkit-animation-name: rotateOut;
    animation-name: rotateOut;
  }
  
  // RotateOutDownLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateOutDownLeft){
    0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      opacity: 1;
    }
  
    100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg);
      opacity: 0;
    }
  }
  
  .rotateOutDownLeft {
    -webkit-animation-name: rotateOutDownLeft;
    animation-name: rotateOutDownLeft;
  }
  
  // RotateOutDownRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateOutDownRight){
    0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      opacity: 1;
    }
  
    100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);
      opacity: 0;
    }
  }
  
  .rotateOutDownRight {
    -webkit-animation-name: rotateOutDownRight;
    animation-name: rotateOutDownRight;
  }
  
  // RotateOutUpLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateOutUpLeft){
    0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      opacity: 1;
    }
  
    100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);
      opacity: 0;
    }
  }
  
  .rotateOutUpLeft {
    -webkit-animation-name: rotateOutUpLeft;
    animation-name: rotateOutUpLeft;
  }
  
  // RotateOutUpRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rotateOutUpRight){
    0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      opacity: 1;
    }
  
    100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate3d(0, 0, 1, 90deg);
      transform: rotate3d(0, 0, 1, 90deg);
      opacity: 0;
    }
  }
  
  .rotateOutUpRight {
    -webkit-animation-name: rotateOutUpRight;
    animation-name: rotateOutUpRight;
  }
  
  // Hinge Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(hinge){
    0% {
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  
    20%, 60% {
      -webkit-transform: rotate3d(0, 0, 1, 80deg);
      transform: rotate3d(0, 0, 1, 80deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }
  
    40%, 80% {
      -webkit-transform: rotate3d(0, 0, 1, 60deg);
      transform: rotate3d(0, 0, 1, 60deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  
    100% {
      -webkit-transform: translate3d(0, 700px, 0);
      transform: translate3d(0, 700px, 0);
      opacity: 0;
    }
  }
  
  .hinge {
    -webkit-animation-name: hinge;
    animation-name: hinge;
  }
  
  // RollIn Keyframes Animation
  // --------------------------------------------------
  
  @-webkit-keyframes rollIn {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
      transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes rollIn {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
      -ms-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
      transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
  
  .rollIn {
    -webkit-animation-name: rollIn;
    animation-name: rollIn;
  }
  
  // RollOut Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(rollOut){
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
      transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    }
  }
  
  .rollOut {
    -webkit-animation-name: rollOut;
    animation-name: rollOut;
  }
  
  // ZoomIn Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomIn){
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  .zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }
  
  // ZoomInDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomInDown){
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
      transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
      -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
      transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    }
  }
  
  .zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown;
  }
  
  // ZoomInLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomInLeft){
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
      transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
      transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    }
  }
  
  .zoomInLeft {
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft;
  }
  
  // ZoomInRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomInRight){
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
      transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
      transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    }
  }
  
  .zoomInRight {
    -webkit-animation-name: zoomInRight;
    animation-name: zoomInRight;
  }
  
  // ZoomInUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomInUp){
    0% {
      opacity: 0;
      -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
      transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
      -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    60% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
      transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    }
  }
  
  .zoomInUp {
    -webkit-animation-name: zoomInUp;
    animation-name: zoomInUp;
  }
  
  // ZoomOut Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomOut){
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
      -webkit-transform: scale3d(.3, .3, .3);
      transform: scale3d(.3, .3, .3);
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
  }
  
  // ZoomOutDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomOutDown){
    40% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
      transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
      -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
      transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom;
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    }
  }
  
  .zoomOutDown {
    -webkit-animation-name: zoomOutDown;
    animation-name: zoomOutDown;
  }
  
  // ZoomOutLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomOutLeft){
    40% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
      transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
      transform: scale(.1) translate3d(-2000px, 0, 0);
      -webkit-transform-origin: left center;
      transform-origin: left center;
    }
  }
  
  .zoomOutLeft {
    -webkit-animation-name: zoomOutLeft;
    animation-name: zoomOutLeft;
  }
  
  // ZoomOutRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomOutRight){
    40% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
      transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
      transform: scale(.1) translate3d(2000px, 0, 0);
      -webkit-transform-origin: right center;
      transform-origin: right center;
    }
  }
  
  .zoomOutRight {
    -webkit-animation-name: zoomOutRight;
    animation-name: zoomOutRight;
  }
  
  // ZoomOutUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(zoomOutUp){
    40% {
      opacity: 1;
      -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
      transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
      -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
      animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
      transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom;
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
    }
  }
  
  .zoomOutUp {
    -webkit-animation-name: zoomOutUp;
    animation-name: zoomOutUp;
  }
  
  // SlideInDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideInDown){
    0% {
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
  
  // SlideInLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideInLeft){
    0% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
  }
  
  // SlideInRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideInRight){
    0% {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }
  
  // SlideInUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideInUp){
    0% {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
  }
  
  // SlideOutDown Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideOutDown){
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    100% {
      visibility: hidden;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  
  .slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
  }
  
  // SlideOutLeft Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideOutLeft){
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    100% {
      visibility: hidden;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .slideOutLeft {
    -webkit-animation-name: slideOutLeft;
    animation-name: slideOutLeft;
  }
  
  // SlideOutRight Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideOutRight){
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    100% {
      visibility: hidden;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  
  .slideOutRight {
    -webkit-animation-name: slideOutRight;
    animation-name: slideOutRight;
  }
  
  // SlideOutUp Keyframes Animation
  // --------------------------------------------------
  
  @include keyframes(slideOutUp){
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    100% {
      visibility: hidden;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  }
  
  .slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;
  }
  
  
  /* bolletjes homepage */
  
  @-webkit-keyframes criss-cross-left {
    0% {
      left: -20px; }
    50% {
      left: 50%;
      width: 20px;
      height: 20px; }
    100% {
      left: 50%;
      width: 375px;
      height: 375px; } }
  
  @keyframes criss-cross-left {
    0% {
      left: -20px; }
    50% {
      left: 50%;
      width: 20px;
      height: 20px; }
    100% {
      left: 50%;
      width: 375px;
      height: 375px; } }
  
  @-webkit-keyframes criss-cross-right {
    0% {
      right: -20px; }
    50% {
      right: 50%;
      width: 20px;
      height: 20px; }
    100% {
      right: 50%;
      width: 375px;
      height: 375px; } }
  
  @keyframes criss-cross-right {
    0% {
      right: -20px; }
    50% {
      right: 50%;
      width: 20px;
      height: 20px; }
    100% {
      right: 50%;
      width: 375px;
      height: 375px; } }



.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}