.header-wrapper {
    #sticky-inner.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99999;
        
        .header {
            padding: 8px 0;
        }
        
        .header-menu {
            .navbar {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .header-top {
        background-color: $primary;
        color: $white;
        padding: 10px 0;

        a {
            color: $white !important;
        }
      
        .article_title, 
        .article_spacer, 
        .article_maintext{
            display:none;
        }
        
        // table {margin: 10px 0px}
    }
    
    .header {
        background-color: $secondary;
        color: $white;
        padding: 20px 0px;
        transition: padding .3s;
        
        a {
            color: $white;
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // width: 70%;
                height: auto;
                width: 100%;
                    
                @include media-breakpoint-up(sm) {
                    height: 50px;
                    width: auto;
                }
            }
        }

        .search {
           
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(md) {
                width: 100%;
            }

            form {
                display:none;
                position: relative;
                width: 75%;

                input {
                    display: none;
                    
                    @include media-breakpoint-up(md) {
                        display: inline;
                        width: 100%;
                    }
                }

                .search-icon {
                    background: none;
                    border: none;

                    .iconify {
                        font-size: 36px;
                    }
                    
                    @include media-breakpoint-up(md) {
                        font-size: 30px;
                        position: absolute;
                        top: 2px;
                        right: 10px;
                        color: $black;
                    }
                }
            }
        }

        .account {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            > a {
                cursor: pointer;
            }

            .iconify {
                font-size: 36px;
            }

            .account_popup {
                position: absolute;
                right: 0;
                top: 100%;
                background: $secondary; 
                padding: 20px;
                z-index: 200;
                min-width: 280px;
                border-radius: 4px;
                
                  .form_input_text {
                        padding: 4px 0px;
                        input {border-radius: 4px;border:0px;padding:4px 10px;}
                        label.form_label {display:none;}
                }
                  input.new_formButton {
                    background-color:$primary;color:$white;border-radius:4px;padding: 4px 10px;border:0px;
                    &:hover {background-color:$yellow;color:$secondary;}
                  }
                  
            }
        }

        .shoppingcart {
            display: flex;
            align-items: center;
            justify-content: center;

            .iconify {
                font-size: 36px;
            }

            span.count {
                position: absolute;
                top: -5px;
                right: -6px;
                background: #e11322;
                color: #fff;
                line-height: 12px;
                font-size: 12px;
                text-align: center;
                padding: 4px 7px 5px 7px;
                border-radius: 100%;
            }

            .dropdown-toggle {
                cursor: pointer;

                &:after {
                    content: "";
                    display: none;
                }
            }
        }
    }
    
    .header-menu {
        .navbar {
            transition: padding .3s;
        }
    }

}
