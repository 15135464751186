.product_list {

    .product_list_sidebar {
      display:none;
        max-width: 250px;
    }

    .product_list_items {
        min-width: 0;
        width: 100%;
        padding-left: 50px;
        
        .product_list_category_highlight {
          .category_description {
            padding: 10px 0px;
          }
        }
        
        .product_list_category_highlight {
          width:100%;
            img {width:100%;}
        }
        
        .product_info {
          position: relative;
          float: left;
          width: 100%;
          height: 280px;
          .brand_name {color: $secondary;margin-top: 20px;}
          h2.product_name {font-size: 1.5rem;width:100%;margin-top: 0px;
            a {color:$black;}
            a:hover {text-decoration:none;color:$secondary;}
          }
          .product_price_advice {font-size: 1.2rem;color:$gray-600;font-weight:normal; text-decoration: line-through;}
          
          .product_price {font-size: 1.5rem;width:100%;color:$primary;font-weight:bold;margin:20px 0px;}
          
             .product_bestellen_button {
              position: absolute;bottom: 35px;
                  select {
                          background-color:$red;
                          border: 0px solid $red;
                          padding: 4px;
                          color:$white;
                  }
                  
                  .product_quantity select {
                    border-radius: 4px 0px 0px 4px;
                  }
                  .product_size select {
                    border-radius: 0px;
                    max-width:130px;
                    width:130px;
                    position: relative;
                  }
                  .product_order {
                    a {
                      position: relative;float:left;
                      background-color:$red;
                      color:$white;
                      padding: 2px 10px 3px 10px;
                      
                      border-radius:4px;
                    }
                    a:hover {background-color:$black}
                 }
            }
          
        }
        .product_detail_simple {
          padding: 10px;
        }
        
       
        .product_detail_simple {
          
          .product_image {
            position: relative;
            float:left;
            width:100%;
            text-align:center;
            max-height:400px;
              img {max-width: 100%;}
              
          }
          
        }
    }
}

.shop_filters {

    .shop_filters_block {
        margin-bottom: 30px;

        .shop_filters_header {
            h2 {
                font-size: 1.3rem;
                padding-bottom: 10px;
                border-bottom: 1px solid $gray-200;
            }
        }

        .shop_filters_list {
            .shop_filters_filter {
                margin-bottom: 20px;

                h3 {
                    font-size: 1rem;
                }
            }

            .shop_filters_item {
                a {
                    color: $dark;
                    padding: 2px 4px;
                }

                &.filter_active a {
                    color: $white;
                    background: $primary;
                    border-radius: 5px;
                }
            }
        }
    }

}


.product_detail {
  h1 {font-size: 2rem;font-weight:bold;}
  .product_left, .product_right {float:left;}
  .product_left {padding:40px 40px 40px 0px}
  .product_right {padding:40px 0px 40px 0px}
  a.btn {float:right;margin-top: -60px;}
  
  
  select {
            background-color:$red;
            border: 0px solid $red;
            padding: 4px;
            color:$white;
          }
  
  .product_quantity select {
           border-radius: 4px 0px 0px 4px;
          }
  
  .product_size_sel select {
            border-radius: 0px;
            max-width:130px;
            width:130px;
            position: relative;
          }
  
    .product_order {
            a {
              position: relative;float:left;
              background-color:$red;
              color:$white;
              padding: 2px 10px 3px 10px;
              border-radius: 0px 4px 4px 0px;
            }
            a:hover {background-color:$black}
          }
   .product_image {
          width:100%;
          float:left;
     
          .product_image_thumbs {
             width:25%;
             float:left;
                  .product_image_thumb {
                    width:80%;padding: 10%;
                    img {width:100%;}
                  }
             
          }
         .bx-wrapper {
            width:75%;
            float:right;
            border:0px  !important;
            box-shadow:0 0 0 $white !important;
            
                .product_image_slide {
                    display: flex;
                        a {
                          display: flex; margin: auto;
                          img {margin: auto;}
                        }
                }
         }
      
   }       

  .product_content {
    h3 {
      font-size: 1.5rem;
      color:$secondary;
    }
    
    
    .product_price_normal {
      font-size: 2.5rem;
      color: $primary;
      margin: 20px 0px;
    }
   
  }
}
    .product_description {
      background-color:$tertiary;
      text-align: center;
      
            h3 {
              font-size: 1.5rem;
              color:$secondary;
            } 
      
    }
    .article_spacer {
          width: 10%;
          background-color: #e11322;
          height: 4px;
          margin: 2% 45%;
     }
          
    .product_stock_message {margin: 10px 0px;}

.product_detail_related {
    h3 {
      font-size: 1.5rem;
      color:$secondary;
      padding: 20px 0px;
      text-align:center;
    }
    .article_spacer {
          width: 10%;
          background-color: #e11322;
          height: 4px;
          margin: 2% 45%;
          }
}
