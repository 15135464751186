
/*cookie policy bar */

#cookiepolicy{font-size:14px;font-weight:bold;z-index:2147483647;position:fixed;left:5px;margin-left:2px;margin-right:2px;}
#cookiepolicy.cookiepolicy-bottom{bottom:0;}
#cookiepolicy.cookiepolicy-top{top:0;}
#cookiepolicy-txt span{display:inline-block}
#cookiepolicy-txt{vertical-align:text-top;line-height:16px;text-align:center;color:#fff;font-size:14px;font-weight:bold;padding: 4px 5px 5px 0;}
#cookiepolicy-accept-cookies{background-color:#ffffff;font-size:14px;font-weight:bold;color:#E16213;opacity:1;padding:2px 8px 4px;cursor:pointer; display:inline-block; float: right;border-radius:12px;border:1px solid #E16213}
#cookiepolicy-accept-cookies:hover {color: #000000;border:1px solid #000000}
#cookiepolicy-txt.cookiepolicy-text-mini {cursor:hand;cursor:pointer;padding-top: 8px;padding-right: 20px;color:#fff !important;}
.cookiepolicy-text-mini:hover {text-decoration:underline;}

#cookiepolicy a,#cookiepolicy a:link,#cookiepolicy a:visited,
#cookiepolicy a:hover{color:#000000;font-weight:bold;text-decoration:underline}

#cookiepolicy-wrapper{width:100%;margin-right:auto;margin-left:auto;position:relative;opacity:0.88;background-color:gray;height:50px;padding:2px}
#cookiepolicy-mini-wrapper{width:8%;position:relative;opacity:0.88;background: url('https://www.dackus.it/images/it/cookie.png') no-repeat right center gray;height:40px;}

#cookiepolicy.cookiepolicy-bottom #cookiepolicy-mini-wrapper,
#cookiepolicy.cookiepolicy-top #cookiepolicy-mini-wrapper{margin-right:auto;margin-left:auto;}

#cookiepolicy.cookiepolicy-bottom-left #cookiepolicy-wrapper,
#cookiepolicy.cookiepolicy-top-left #cookiepolicy-wrapper,
#cookiepolicy.cookiepolicy-bottom-left #cookiepolicy-mini-wrapper,
#cookiepolicy.cookiepolicy-top-left #cookiepolicy-mini-wrapper{float:left;}

#cookiepolicy.cookiepolicy-bottom-right #cookiepolicy-wrapper,
#cookiepolicy.cookiepolicy-top-right #cookiepolicy-wrapper,
#cookiepolicy.cookiepolicy-bottom-right #cookiepolicy-mini-wrapper,
#cookiepolicy.cookiepolicy-top-right #cookiepolicy-mini-wrapper{float:right;}

#cookiepolicy.cookiepolicy-bottom.cookiepolicy-rounded #cookiepolicy-wrapper{border-radius: 10px 10px 0px 0px;}
#cookiepolicy.cookiepolicy-top.cookiepolicy-rounded #cookiepolicy-wrapper{border-radius: 0px 0px 10px 10px;}

#cookiepolicy.cookiepolicy-bottom.cookiepolicy-rounded #cookiepolicy-mini-wrapper{border-radius: 10px 10px 0px 0px;}
#cookiepolicy.cookiepolicy-top.cookiepolicy-rounded #cookiepolicy-mini-wrapper{border-radius: 0px 0px 10px 10px;}

#cookiepolicy.cookiepolicy-bottom.cookiepolicy-bborder #cookiepolicy-wrapper{border: 2px solid #c8352f;background-color: rgba(0, 0, 0, 0.88) !important;}
#cookiepolicy.cookiepolicy-top.cookiepolicy-bborder #cookiepolicy-wrapper{border: 2px solid #c8352f;}

#cookiepolicy.cookiepolicy-bottom.cookiepolicy-bborder #cookiepolicy-mini-wrapper{border: 2px solid #c8352f;background-color: rgba(0, 0, 0, 0.88) !important;min-width: 200px;}
#cookiepolicy.cookiepolicy-top.cookiepolicy-bborder #cookiepolicy-mini-wrapper{border: 2px solid #c8352f;}

#cookiepolicy.cookiepolicy-bottom #cookiepolicy-wrapper{}
#cookiepolicy.cookiepolicy-top #cookiepolicy-wrapper{}

#cookiepolicy.cookiepolicy-bottom #cookiepolicy-mini-wrapper{}
#cookiepolicy.cookiepolicy-top #cookiepolicy-mini-wrapper{}

#cookiepolicy-notice{padding:5px 0 2px;color:#fff;}

#cookiepolicy a, #cookiepolicy a:link, #cookiepolicy a:visited, #cookiepolicy a:hover {color: #fff !important;}