.chkwin-bringtofront {
    z-index: 8020;   
}

.chkwin-tmp iframe, .chkwin-tmp object {
    vertical-align: top;
    padding: 0;
    margin: 0;
}

.chkwin-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

.chkwin-skin {
    position: relative;
    padding: 0;
    margin: 0;
    background: #f9f9f9;
    color: #444;
    text-shadow: none;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
}

.chkwin-wrap.chkwin-without-border .chkwin-skin{
    -webkit-border-radius: 0px;
       -moz-border-radius: 0px;
            border-radius: 0px;    
}


.chkwin-opened {
    z-index: 8030;
}

.chkwin-opened .chkwin-skin {
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
       -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}


.chkwin-outer, .chkwin-inner {
    padding: 0;
    margin: 0;
    position: relative;
    outline: none;
}

.chkwin-inner {
    overflow: hidden;
}

.chkwin-withoutoverflow{
    overflow: visible;
}

.chkwin-type-iframe .chkwin-inner {
    -webkit-overflow-scrolling: touch;
}

.chkwin-error {
    color: #444;
    font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin: 0;
    padding: 10px;
}

.chkwin-image, .chkwin-iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    vertical-align: top;
}

.chkwin-image {
    max-width: 100%;
    max-height: 100%;
}

#chkwin-loading, .chkwin-close, .chkwin-prev span, .chkwin-next span {
    background-image: url('chkwin_sprite.png');
}

#chkwin-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8020;
}

#chkwin-loading div {
    width: 44px;
    height: 44px;
    background: url('chkwin_loading.gif') center center no-repeat;
}

.chkwin-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
}

.chkwin-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    background: transparent url('blank.gif'); /* helps IE */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    z-index: 8040;
}

.chkwin-prev {
    left: 0;
}

.chkwin-next {
    right: 0;
}

.chkwin-nav span {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    cursor: pointer;
    z-index: 8040;
    visibility: hidden;
}

.chkwin-prev span {
    left: 20px;
    background-position: 0 -36px;
}

.chkwin-next span {
    right: 20px;
    background-position: 0 -72px;
}

.chkwin-nav:hover span {
    visibility: visible;
}

.chkwin-tmp {
    position: absolute;
    top: -9999px;
    left: -9999px;
    padding: 0;
    overflow: visible;
    visibility: hidden;
}

/* Overlay helper */

#chkwin-overlay {
    position: absolute;
    top: 145px;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: #000;
}

@media only screen and (max-width: 414px) {
	#chkwin-overlay {
	    position: absolute;
	    top: 38%;
	    left: 0;
	    overflow: hidden;
	    display: none;
	    z-index: 8010;
	    background: #000;
	}
}

#chkwin-overlay.overlay-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    top: 145px;
}

/* Title helper */

.chkwin-title {
    visibility: hidden;
    font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    position: relative;
    text-shadow: none;
    z-index: 8050;
}

.chkwin-opened .chkwin-title {
    visibility: visible;
}

.chkwin-title-float-wrap {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-bottom: -35px;
    z-index: 8030;
    text-align: center;
}

.chkwin-title-float-wrap .child {
    display: inline-block;
    margin-right: -100%;
    padding: 2px 20px;
    background: transparent; /* Fallback for web browsers that doesn't support RGBa */
    background: rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 15px;
       -moz-border-radius: 15px;
            border-radius: 15px;
    text-shadow: 0 1px 2px #222;
    color: #FFF;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
}

.chkwin-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}

.chkwin-title-inside-wrap {
    margin-top: 10px;
}

.chkwin-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8);
}